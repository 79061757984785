<template>
    <Popup
        bottom
        full-width
        offset-x
        offset-y
        right
        use-backdrop>
        <template #activator="{ showHandler: showProducersHandler }">
            <div :class="classes" class="games-list-toolbar" @click.stop>
                <div>
                    <div v-if="isLogged" class="games-list-toolbar__nav-item">
                        <FavoriteGamesNavButton/>
                    </div>
                </div>
                <div v-if="showProducers" class="games-list-toolbar__producers">
                    <GameProducers @open="showProducersHandler"/>
                </div>
                <CategoriesGamesButton v-if="isMobile" :categories="getMenu"/>
                <div v-else class="game-nav__wrapper">
                    <NavRoll
                        :nav-list="getMenu"
                        class="games-list-toolbar__nav-roll"
                        items-location="toolbar"
                        show-more-title
                    />
                </div>
            </div>
        </template>

        <template #default="{ hideHandler: hideProducersHandler }">
            <ProducersPopup :close-handler="hideProducersHandler">
                <template #item="{item}">
                    <slot
                        :close-handler="hideProducersHandler"
                        :item="item"
                        name="producersItem"
                    />
                </template>
            </ProducersPopup>
        </template>
    </Popup>
</template>

<script>
import { mapActions, mapState } from "pinia";
import Popup from "@components/Popup/Popup.vue";
import GameProducers from "@modules/GameProducers/GameProducers.vue";
import ProducersPopup from "@modules/GameProducers/components/ProducersPopup/ProducersPopup.vue";
import FavoriteGamesNavButton from "@modules/FavoriteGames/FavoriteGamesNavButton.vue";
import CategoriesGamesButton from "@components/CategoriesGames/CategoriesGamesButton.vue";
import NavRoll from "@components/NavRoll/NavRoll.vue";
import { CATEGORY_ICONS } from "@config/categoriesIcons";
import { routeNames } from "@router/routeNames";
import { MenuCollectionsMame, SlugCategoriesGames } from "@theme/configs/categoryesGames";
import { useCommon } from "@store/common";
import { useJackpots } from "@store/jackpots";
import { useLicense } from "@helpers/license";
import { useGamesCommon } from "@store/games/gamesStore";
import { useRootStore } from "@store/root";

export default {
    name: "GamesListToolbar",

    components: {
        Popup,
        GameProducers,
        ProducersPopup,
        FavoriteGamesNavButton,
        CategoriesGamesButton,
        NavRoll,
    },

    props: {
        showProducers: {
            type: Boolean,
            default: true,
        },
    },
    emits: [ "searchToggle" ],

    setup() {
        const { isLicenseEE } = useLicense();

        return {
            isLicenseEE,
        };
    },

    data() {
        return {
            searchOpen: false,
            searchQuery: "",
        };
    },

    computed: {
        ...mapState(useRootStore, {
            isMobile: "isMobile",
        }),
        ...mapState(useGamesCommon, [ "gamesCategories" ]),
        ...mapState(useJackpots, [ "jackpotsActiveList" ]),


        classes() {
            return {
                "games-list-toolbar--search-open": this.searchOpen,
                "games-list-toolbar--is-mobile": this.isMobile,
            };
        },
        slug() {
            const collectionByRoute = {
                [routeNames.main]: MenuCollectionsMame.MAIN_PAGE_CATEGORIES,
                [routeNames.categoryGames]: MenuCollectionsMame.MAIN_PAGE_CATEGORIES,
                [routeNames.favoriteGames]: MenuCollectionsMame.MAIN_PAGE_CATEGORIES,
                [routeNames.categoryPokiesAll]: MenuCollectionsMame.POKIES_PAGE_CATEGORIES,
                [routeNames.categoryPokies]: MenuCollectionsMame.POKIES_PAGE_CATEGORIES,
                [routeNames.categoryLiveAll]: MenuCollectionsMame.LIVE_PAGE_CATEGORIES,
                [routeNames.categoryTable]: MenuCollectionsMame.LIVE_PAGE_CATEGORIES,
                [routeNames.producersGames]: MenuCollectionsMame.PRODUCER_PAGE_CATEGORIES,
            };

            return collectionByRoute[this.$route.name];
        },

        routeParent() {
            const collectionByRoute = {
                [routeNames.main]: routeNames.categoryGames,
                [routeNames.categoryGames]: routeNames.categoryGames,
                [routeNames.favoriteGames]: routeNames.categoryGames,
                [routeNames.categoryPokiesAll]: routeNames.categoryPokies,
                [routeNames.categoryPokies]: routeNames.categoryPokies,
                [routeNames.categoryLiveAll]: routeNames.categoryTable,
                [routeNames.categoryTable]: routeNames.categoryTable,
                [routeNames.producersGames]: routeNames.categoryGames,
            };

            return collectionByRoute[this.$route.name];
        },

        menuSlugs() {
            return this.getMenuCategoriesBySlug(this.slug);
        },

        getMenu() {
            let menuPreparing = [ ...this.menuSlugs ]
                .map((slug) => {
                    const name = this.getMenuName(slug);
                    if (slug === SlugCategoriesGames.SLUG_CATEGORY_BTC_GAMES && this.isLicenseEE) {
                        return null;
                    }

                    if (name) {
                        return {
                            image: slug && CATEGORY_ICONS[slug],
                            title: this.$te(`GAME_NAV.CATEGORIES.${name}`) ? this.$t(`GAME_NAV.CATEGORIES.${name}`) : name,
                            url: { name: this.routeParent, params: { slug } },
                        };
                    }
                })
                .filter((el) => el);


            if (
                this.$route.name === routeNames.categoryLiveAll ||
                this.$route.name === routeNames.categoryTable
            ) {
                menuPreparing = [
                    ...menuPreparing,
                    {
                        title: this.$t("GAME_HALL.All"),
                        url: { name: routeNames.categoryLiveAll },
                    } ];
            }

            return menuPreparing;
        },
    },

    methods: {
        ...mapActions(useCommon, [ "getMenuCategoriesBySlug" ]),

        searchToggle(isOpen) {
            this.searchOpen = isOpen;
            this.$emit("searchToggle", isOpen);
        },

        getMenuName(slug) {
            const category = this.gamesCategories.find((cat) => {
                return cat.slug === slug;
            });
            return category?.name;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@theme/styles";

.game-nav {
    .games-list-toolbar {
        &__producers {
            border-left: none;
        }

        :deep(.games-list-toolbar__nav-roll) {
            .nav-roll {
                grid-gap: .5rem
            }

            .nav-roll-item {
                padding: .5rem 1rem;
            }
        }
    }

    .icon-nav {
        @include media(M) {
            padding: 0;
            margin: 0 1rem;
        }

    }
}


.games-list-toolbar {
    position: relative;
    display: grid;
    grid-template-columns: max-content max-content minmax(5rem, 1fr);
    grid-template-rows: 100%;
    height: 100%;

    :deep(.game-nav__wrapper) {
        padding: .25rem 0 .25rem .25rem;

        .nav-roll-item {
            color: var(--color-text-body);
            border-radius: var(--border-radius-m);
        }

        .nav-roll-item.active {
            background: var(--color-tertiary-2);
        }

        .nav-roll-item:hover {
            background: var(--color-tertiary-3);
            color: var(--color-text-alt);
        }

        .nav-roll__wrapper {
            @include media(M) {
                display: block;
            }
        }

        .nav-roll__dropdown {
            padding: .5rem;
        }
    }

    &--is-mobile {
        grid-template-columns: max-content 1fr 1fr;
    }

    &__producers {
        display: flex;
        justify-content: center;
        align-items: center;

        @include media(L) {
            border-left: none;
        }
    }

    &__search {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        right: 0;
        height: 100%;
        border: none;
        transition: all 0.2s;
    }

    &--search-open &__search {
        width: 100%;
    }

    &__nav-item {
        position: relative;

        &:after {
            content: "";
            width: 1px;
            height: 100%;
            position: absolute;
            right: 0;
            background-color: var(--color-layer-alt-3);
            margin: auto;
            top: 0;
            bottom: 0;
        }
    }
}

</style>
