<template>
    <ModalCommonSlot class="producers-list" :title="$t('PRODUCERS_POPUP.PRODUCERS')">
        <div class="producers-list__wrapper-content">
            <GameProducersSearchInput v-model="searchInput"/>

            <div class="producers-list__wrapper">
                <transition-group
                    class="producers-list__wrapper-body producers-block"
                    mode="in-out"
                    name="producers-list-item"
                    tag="div"
                >
                    <ProducersListItem
                        v-for="item in filteredProducers"
                        :key="item.id"
                        :icon="item.image"
                        :label="item.name"
                        :slug="item.slug"
                        @click="selectProducer(item)"
                    />
                </transition-group>
                <div
                    v-if="showEmptyText"
                    class="text--align-center"
                >
                    <p v-t="'PRODUCERS_POPUP.SEARCH_INPUT.EMPTY_RESULT.TITLE'" class="font__caption-1"/>
                    <p v-t="'PRODUCERS_POPUP.SEARCH_INPUT.EMPTY_RESULT.DESC'" class="text-color--body"/>
                </div>
            </div>
        </div>
    </ModalCommonSlot>
</template>

<script setup lang="ts">
import { useGameProducerSearch } from "@modules/GameProducers/components/useGameProducerSearch";
import { routeNames } from "@router/routeNames";

import ProducersListItem from "@components/ProducersListItem/ProducersListItem.vue";
import ModalCommonSlot from "@components/ModalCommonSlot/ModalCommonSlot.vue";
import GameProducersSearchInput
    from "../GameProducersSearchInput.vue";
import { useRouter } from "vue-router";

interface Props {
    closeHandler: () => void;
}

const props = defineProps<Props>();
const $router = useRouter();
const { searchInput, filteredProducers, showEmptyText } = useGameProducerSearch();

function selectProducer(item) {
    $router.push({ name: routeNames.producersGames, params: { slug: item.slug } });
    props.closeHandler();
}

</script>

<style scoped lang="scss" src="./style.scss"></style>
