<script setup lang="ts">
import featureFlags from "@theme/configs/featureFlags";
import { themes } from "@ui/New/ButtonConfig";
import FeButtonNew from "@ui/New/FeButton.vue";
import { computed } from "vue";

import FeButton, { themes as themesButton } from "@ui/FeButton/FeButton.vue";
import FeInput from "@ui/FeInput/FeInput.vue";
import FeIcon, { icons } from "@ui/FeIcon/FeIcon";

const emits = defineEmits([ "update:modelValue", "change" ]);

const props = defineProps<{
    modelValue: string;
}>();

const valueProcessed = computed({
    get() {
        return props.modelValue;
    },
    set(val) {
        emits("update:modelValue", val.trim());
    },
});
</script>

<template>
    <div class="game-producers-search-input">
        <FeInput
            v-model="valueProcessed"
            :placeholder="$t('PRODUCERS_POPUP.SEARCH_INPUT.PLACEHOLDER')"
        >
            <template #left>
                <FeIcon :icon="icons.outline.search"/>
            </template>

            <template v-if="valueProcessed" #right>
                <FeButtonNew
                    v-if="featureFlags.enableNewUIComponents"
                    :theme="themes.SecondarySmall"
                    @click.stop="emits('update:modelValue', '')">
                    {{ $t("PRODUCERS_POPUP.SEARCH_INPUT.CLEAR_BUTTON") }}
                </FeButtonNew>
                <FeButton
                    v-else
                    :theme="themesButton.SecondaryExtraSmall"
                    @click.stop="emits('update:modelValue', '')"
                >
                    {{ $t("PRODUCERS_POPUP.SEARCH_INPUT.CLEAR_BUTTON") }}
                </FeButton>
            </template>
        </FeInput>
    </div>
</template>

<style scoped lang="scss">
.game-producers-search-input {
    position: sticky;
    top: 0;
    padding-bottom: 1rem;
    background: var(--color-layer-alt-1);
    z-index: 9999;
}
</style>
