<template>
    <div
        class="games-popup__activator"
        @click.stop="handleClickOpenCategories"
    >
        <FeIcon :icon="FeIconConfig.icons.outline.category" class="games-popup__icon" />
        {{ $t("CATEGORIES_POPUP.BUTTON_OPEN") }}
    </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";

import FeIcon, * as FeIconConfig from "@ui/FeIcon/FeIcon";
import Modal from "@plugins/Modal";
import CategoriesGamesPopup from "@components/CategoriesGames/CategoriesGamesPopup.vue";

import type { ICategory } from "@src/types/caterogiesGames";


interface Props {
    categories: ICategory[];
}

const props = defineProps<Props>();
const $route = useRoute();
const { t } = useI18n();

const popupTitle = computed<string>(() => {
    if ($route.fullPath.includes("pokies")) {
        return t("CATEGORIES_POPUP.TITLE_SLOTS");
    }
    if ($route.fullPath.includes("live")) {
        return t("CATEGORIES_POPUP.TITLE_LIVE");
    }

    return t("CATEGORIES_POPUP.TITLE_MAIN");
});

function handleClickOpenCategories(): void {
    Modal.show({
        mobileFriendly: true,
        name: "modal-categories-games-popup",
        component: CategoriesGamesPopup,
        props: {
            categories: props.categories,
            title: popupTitle.value,
        },
    });
}
</script>

<style lang="scss" scoped>
@import "~@theme/styles";

.games-popup {
    &__activator {
        @include font-size--Caption1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 1rem;
    }

    &__icon {
        margin-right: .5rem;
    }
}
</style>
