<template>
    <div class="game-producers">
        <div
            class="game-producers__activator"
            @click.stop="open"
        >
            <FeIcon :icon="FeIconConfig.icons.outline.providers" class="game-producers__icon"/>
            <p class="game-producers__title">
                {{ $t("GAME_NAV.PRODUCERS") }}
            </p>
        </div>
    </div>
</template>

<script>
import FeIcon, * as FeIconConfig from "@ui/FeIcon/FeIcon";
import ProducersList from "@modules/GameProducers/components/ProducersList/ProducersList.vue";
import { useUIStore } from "@store/ui";
import { mapState } from "pinia";
import { useRootStore } from "@store/root";

export default {
    name: "GameProducers",

    components: {
        FeIcon,
    },
    emits: [ "open" ],

    setup() {
        const { setShowModal } = useUIStore();

        return {
            setShowModal,
        };
    },

    data() {
        return {
            FeIconConfig,
        };
    },

    computed: {
        ...mapState(useRootStore, {
            isMobile: "isMobile",
        }),
    },

    methods: {
        open() {
            if (this.isMobile) {
                this.setShowModal({
                    name: "modal-producer",
                    component: ProducersList,
                    mobileFriendly: true,
                    showClose: true,
                });
            } else {
                this.$emit("open");
            }
        },
    },
};
</script>

<style lang="scss" src="./style.scss"></style>
