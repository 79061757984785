<template>
    <div class="icon-button" :class="classes" @click="clickHandler">
        <div class="icon-button__icon">
            <slot name="icon" />
        </div>
        <div class="icon-button__content">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: "FeMediaButton",
    props: {
        vertical: {
            type: Boolean,
        },
        horizontal: {
            type: Boolean,
        },
        adaptive: {
            type: Boolean,
            default: true,
        },
        showIcon: {
            type: Boolean,
            default: true,
        },
    },
    emits: [ "click" ],
    computed: {
        classes() {
            const classes = [];
            if (this.vertical) {
                classes.push("icon-button--vertical");
            } else if (this.horizontal) {
                classes.push("icon-button--horizontal");
            } else if (this.adaptive) {
                classes.push("icon-button--adaptive");
            }
            if (!this.showIcon) {
                classes.push("icon-button--hide-icon");
            }
            return classes;
        },
    },
    methods: {
        clickHandler($event) {
            this.$emit("click", $event);
        },
    },
};
</script>

<style lang="scss" src="./style.scss"></style>
