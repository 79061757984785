<template>
    <div class="producers-popup gap-row-m">
        <div class="producers-popup__header">
            <i></i>
            <h3 v-t="'PRODUCERS_POPUP.PRODUCERS'"/>
            <CloseButton class="producers-popup__close" @click="closeHandler"/>
        </div>
        <GameProducersSearchInput v-model="searchInput"/>

        <div v-if="!showEmptyText">
            <transition-group
                class="producers-popup__content producers-block gap-s"
                mode="in-out"
                name="producers-item"
                tag="div"
            >
                <div v-for="item in filteredProducers" :key="item.id" class="producers-item">
                    <slot
                        name="item"
                        :item="item"
                        :close-handler="closeHandler"
                    />
                </div>

            </transition-group>
        </div>

        <div
            v-else
            class="text--align-center"
        >
            <p v-t="'PRODUCERS_POPUP.SEARCH_INPUT.EMPTY_RESULT.TITLE'" class="font__caption-1"/>
            <p v-t="'PRODUCERS_POPUP.SEARCH_INPUT.EMPTY_RESULT.DESC'" class="text-color--body"/>
        </div>

    </div>
</template>

<script setup lang="ts">
import { useGameProducerSearch } from "@modules/GameProducers/components/useGameProducerSearch";

import CloseButton from "@modules/Auth/components/CloseButton/CloseButton.vue";
import GameProducersSearchInput from "@modules/GameProducers/components/GameProducersSearchInput.vue";

interface Props {
    closeHandler: () => void;
}

defineProps<Props>();
const { searchInput, filteredProducers, showEmptyText } = useGameProducerSearch();

</script>

<style scoped lang="scss" src="./style.scss"></style>
