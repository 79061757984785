<template>
    <router-link
        v-slot="{isActive, navigate}"
        :to="{name: routeNames.favoriteGames}"
        custom
    >
        <a
            v-bind="$attrs"
            class="favorite__link"
            :class="{'active': isActive}"
            @click="navigate"
        >
            <FeIcon
                :icon="isActive ? FeIconConfig.icons.fill.favoriteActive : FeIconConfig.icons.outline.favoriteDisable"
            />
        </a>
    </router-link>
</template>

<script>
import FeIcon, * as FeIconConfig from "@ui/FeIcon/FeIcon";
import { routeNames } from "@router/routeNames";

export default {
    name: "FavoriteGamesNavButton",

    components: {
        FeIcon,
    },

    data() {
        return {
            FeIconConfig,
            routeNames,
        };
    },
};
</script>

<style scoped lang="scss">
@import "~@theme/styles";

.favorite {
    &__link {
        display: inline-block;
        color: var(--color-text-alt);
        padding: .75rem 1rem;
        border-radius: var(--border-radius-m) 0 0 var(--border-radius-m);
        cursor: pointer;

        &.active {
            background: var(--color-tertiary-2);
        }

        &:hover {
            background: var(--color-tertiary-3);
        }
    }

}

</style>
