import { useGamesProviders } from "@store/games/gamesProviders";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";

export function useGameProducerSearch() {
    const { gamesProviders } = storeToRefs(useGamesProviders());

    const searchInput = ref("");

    const filteredProducers = computed(() => {
        return gamesProviders.value.filter((item) => {
            return item.name.toLowerCase().includes(searchInput.value.toLowerCase());
        });
    });

    const showEmptyText = computed(() => {
        return filteredProducers.value.length === 0;
    });

    return {
        searchInput,
        filteredProducers,
        showEmptyText,
    };
}
