import topIcon from "@theme/icons/categories/top.svg";
import jackpotIcon from "@theme/icons/categories/jackpots.svg";
import recommendedIcon from "@theme/icons/categories/recommended.svg";
import newIcon from "@theme/icons/categories/new.svg";
import bonusWageringIcon from "@theme/icons/categories/bonus-wagering.svg";
import megawaysIcon from "@theme/icons/categories/megaways.svg";
import highVolatilityIcon from "@theme/icons/categories/high-volatility.svg";
import lowVolatilityIcon from "@theme/icons/categories/low-volatility.svg";
import bonusBuyIcon from "@theme/icons/categories/bonus-buy.svg";
import blackjackIcon from "@theme/icons/categories/blackjack.svg";
import rouletteIcon from "@theme/icons/categories/roulette.svg";
import baccaratIcon from "@theme/icons/categories/baccarat.svg";
import pokerIcon from "@theme/icons/categories/poker.svg";
import tableIcon from "@theme/icons/categories/table.svg";
import cryptoGamesIcon from "@theme/icons/categories/crypto-games.svg";
import gameShowsIcon from "@theme/icons/categories/game-shows.svg";
import mysticJackpotsIcon from "@theme/icons/categories/mystery-jackpots.svg";
import bngTournamentIcon from "@theme/icons/categories/tournament-games.svg";
import plsTournamentIcon from "@theme/icons/categories/tournament-provider.svg";
import egyptGamesIcon from "@theme/icons/categories/egypt-games.svg";
import fruitsGamesIcon from "@theme/icons/categories/fruits-games.svg";
import fishingGamesIcon from "@theme/icons/categories/fishing-games.svg";
import booksGamesIcon from "@theme/icons/categories/books-games.svg";
import crashGamesIcon from "@theme/icons/categories/crash-games.svg";
import lotteryGamesIcon from "@theme/icons/categories/lottery-games.svg";
import coins from "@theme/icons/categories/coins.svg";

import { SlugCategoriesGames } from "@theme/configs/categoryesGames";


export const CATEGORY_ICONS = {
    [SlugCategoriesGames.SLUG_CATEGORY_NEW]: newIcon,
    [SlugCategoriesGames.SLUG_CATEGORY_BONUS_WAGERING]: bonusWageringIcon,
    [SlugCategoriesGames.SLUG_CATEGORY_MEGAWAYS]: megawaysIcon,
    [SlugCategoriesGames.SLUG_CATEGORY_HIGH_VOLATILITY]: highVolatilityIcon,
    [SlugCategoriesGames.SLUG_CATEGORY_LOW_VOLATILITY]: lowVolatilityIcon,
    [SlugCategoriesGames.SLUG_CATEGORY_BONUS_BUY]: bonusBuyIcon,
    [SlugCategoriesGames.SLUG_CATEGORY_BLACKJACK]: blackjackIcon,
    [SlugCategoriesGames.SLUG_CATEGORY_ROULETTE]: rouletteIcon,
    [SlugCategoriesGames.SLUG_CATEGORY_BACCARAT]: baccaratIcon,
    [SlugCategoriesGames.SLUG_CATEGORY_POKER]: pokerIcon,
    [SlugCategoriesGames.SLUG_CATEGORY_TABLE]: tableIcon,
    [SlugCategoriesGames.SLUG_CATEGORY_BTC_GAMES]: cryptoGamesIcon,
    [SlugCategoriesGames.SLUG_CATEGORY_GAME_SHOWS]: gameShowsIcon,
    [SlugCategoriesGames.SLUG_CATEGORY_MYSTIC_JACKPOTS]: mysticJackpotsIcon,
    [SlugCategoriesGames.SLUG_CATEGORY_BNG_TOURNAMENT]: bngTournamentIcon,
    [SlugCategoriesGames.SLUG_CATEGORY_PLS_TOURNAMENT]: plsTournamentIcon,
    [SlugCategoriesGames.SLUG_CATEGORY_JACKPOT]: jackpotIcon,
    [SlugCategoriesGames.SLUG_CATEGORY_TOP]: topIcon,
    [SlugCategoriesGames.SLUG_CATEGORY_RECOMMENDED]: recommendedIcon,
    [SlugCategoriesGames.SLUG_CATEGORY_EGYPT_GAMES]: egyptGamesIcon,
    [SlugCategoriesGames.SLUG_CATEGORY_FRUITS_GAMES]: fruitsGamesIcon,
    [SlugCategoriesGames.SLUG_CATEGORY_BOOKS_GAMES]: booksGamesIcon,
    [SlugCategoriesGames.SLUG_CATEGORY_CRASH_GAMES]: crashGamesIcon,
    [SlugCategoriesGames.SLUG_CATEGORY_LOTTERY_GAMES]: lotteryGamesIcon,
    [SlugCategoriesGames.SLUG_CATEGORY_FISHING_GAMES]: fishingGamesIcon,
    [SlugCategoriesGames.SLUG_CATEGORY_COINS]: coins,
};
