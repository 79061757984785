<template>
    <div
        class="producers-list-item"
        :class="{ 'producers-list-item--active': active }"
        @click="clickHandler"
    >
        <FeMediaButton
            horizontal
            :show-icon="showIcon"
        >
            <div class="producers-list-item__container">
                <ProducerImage :producer="slug" :type="ProducerImageConfig.THEMES.DARK" />
            </div>
        </FeMediaButton>
    </div>
</template>

<script>
import FeMediaButton from "@components/FeMediaButton/FeMediaButton.vue";

import ProducerImage, * as ProducerImageConfig from "@components/ProducerImage/ProducerImage.vue";

export default {
    name: "ProducersListItem",

    components: {
        FeMediaButton,
        ProducerImage,
    },

    props: {
        active: {
            type: Boolean,
        },
        icon: {
            type: String,
            default: "",
        },
        label: {
            type: String,
            default: "",
        },
        showIcon: {
            type: Boolean,
            default: true,
        },
        slug: {
            type: String,
            default: "",
        },
    },
    emits: [ "click" ],

    data() {
        return {
            ProducerImageConfig,
        };
    },

    methods: {
        clickHandler($event) {
            this.$emit("click", $event);
        },
    },
};
</script>

<style lang="scss" src="./style.scss"></style>
<style lang="scss" src="../../modules/Footer/components/ProducersBlock/style.scss"></style>
