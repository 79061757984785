<template>
    <ModalCommonSlot
        :title="title"
    >
        <div class="categories__wrap gap-xs">
            <template v-if="featureFlags.enableNewUIComponents">
                <router-link
                    v-for="categoryItem in gameCategories"
                    :key="categoryItem.title"
                    :to="categoryItem.url || categoryItem.to"
                    class="categories-card-button"
                    @click="closeHandler"
                >
                    <FeButtonNew
                        :theme="themes.SlabMedium"
                        full-width
                        :slab-image="categoryItem.image"
                        @click="closeHandler"
                    >
                        {{ categoryItem.title }}
                    </FeButtonNew>
                </router-link>
            </template>
            <template v-else>
                <router-link
                    v-for="categoryItem in categories"
                    :key="categoryItem.title"
                    :to="categoryItem.url || categoryItem.to"
                    class="categories-card"
                    @click="closeHandler"
                >
                    <FeImage
                        v-if="categoryItem.image"
                        :src="categoryItem.image"
                        class="categories-card__img"
                    />
                    {{ categoryItem.title }}
                </router-link>
            </template>
        </div>

        <router-link
            v-if="featureFlags.enableNewUIComponents"
            :to="generalCategories.url || generalCategories.to"
            class="categories-card-button"
            @click="closeHandler"
        >
            <FeButtonNew
                :theme="themes.TertiaryLarge"
                full-width
            >
                {{ generalCategories.title }}
            </FeButtonNew>
        </router-link>
    </ModalCommonSlot>
</template>

<script lang="ts" setup>
import ModalCommonSlot from "@components/ModalCommonSlot/ModalCommonSlot.vue";
import type { ICategory } from "@src/types/caterogiesGames";
import featureFlags from "@theme/configs/featureFlags";
import FeImage from "@ui/FeImage/FeImage.vue";
import { themes } from "@ui/New/ButtonConfig";
import FeButtonNew from "@ui/New/FeButton.vue";
import { ref } from "vue";

interface Props {
    closeHandler: () => void;
    title: string;
    categories: ICategory[];
}

const props = defineProps<Props>();

const gameCategories = ref();
const generalCategories = ref();
if (featureFlags.enableNewUIComponents) {
    gameCategories.value = props.categories;
    generalCategories.value = gameCategories.value.pop();
}
</script>

<style lang="scss" scoped>
@import "~@theme/styles";

.categories {
    &__wrap {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 1fr;
    }

    &-card {
        @include font-size--SmallCaption;
        text-decoration: none;
        color: var(--color-text-alt);
        border-radius: var(--border-radius-s);
        background: var(--color-tertiary-3);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: .5rem;

        &__img {
            width: 1.5rem;
            height: 1.5rem;
            margin-bottom: .25rem;
        }

        &.router-link-active {
            background: var(--color-tertiary-2);
        }
    }

    &-card-button {
        text-decoration: none;
    }

}
</style>
