<template>
    <div class="game-hall grid-content gap-m">
        <div
            :class="{ 'is-open': searchOpen, 'game-nav--sticky': isMobile, 'is-sticky': isSticky }"
            class="game-nav grid-full-width mobile-layout-width"
        >
            <div class="game-nav__inner">
                <GamesListToolbar
                    @search-toggle="searchOpen = !searchOpen"
                >
                    <template #producersItem="{item, closeHandler}">
                        <router-link
                            v-slot="{ navigate, isActive }"
                            :to="item.url"
                            custom
                        >
                            <ProducersListItem
                                :active="isActive"
                                :icon="item.image"
                                :label="item.name"
                                :slug="item.slug"
                                @click="e => {navigate(e); closeHandler()}"
                            />
                        </router-link>
                    </template>
                </GamesListToolbar>
            </div>
        </div>
        <div class="game-hall__row grid-layout-column grid-full-width">
            <router-view
                ref="container"
                class="game-hall__col grid-content gap-m"/>
            <Winners
                :class="{ 'on-main': isMainOrLobby }"
                :delay="0"
                class="grid-sidebar mobile-layout-width"
            />
        </div>
        <ReadMoreBlock
            v-if="showReadMoreBlock"
            :start-height="isMain ? 400 : 20"
            class="game-hall-info-block mobile-layout-width grid-center-position"
        >
            <div v-if="currentPageSeo" class="game-hall__content gap-s">
                <div class="game-hall__content-inner" v-html="currentPageSeo"/>
            </div>
        </ReadMoreBlock>
    </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useGamesProviders } from "@store/games/gamesProviders";
import { routeNames } from "@router/routeNames";
import emptySearch from "@theme/images/empty_search.svg";

import Winners from "@components/Winners/Winners.vue";
import GamesListToolbar from "@components/GamesListToolbar/GamesListToolbar.vue";
import ProducersListItem from "@components/ProducersListItem/ProducersListItem.vue";
import { useStickyMenu } from "@mixins/StickyMenu";

import ReadMoreBlock from "@components/ReadMoreBlock/ReadMoreBlock.vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { useCMS } from "@store/CMS";
import { useGamesCommon } from "@store/games/gamesStore";
import { useGamesCategory } from "@store/games/gamesCategory";
import { useUserInfo } from "@store/user/userInfo";

export default {
    name: "MainSection",

    components: {
        Winners,
        GamesListToolbar,
        ProducersListItem,
        ReadMoreBlock,
    },

    props: {
        stickyEnable: {
            type: Boolean,
            default: true,
        },
    },

    setup(props) {
        const { isMobile, isSticky } = useStickyMenu(props.stickyEnable);
        const { setSeoMeta, setCurrentPageSeoDescription } = useCMS();

        return {
            isMobile,
            isSticky,
            ...useI18n(),
            setSeoMeta,
            setCurrentPageSeoDescription,
        };
    },

    data() {
        return {
            emptySearch,
            searchOpen: false,
            timerId: null,
            page: 1,
            navigateSlugWithPin: [
                "table",
            ],
        };
    },

    computed: {
        ...mapState(useUserInfo, {
            isLogged: "getIsLogged",
        }),
        ...mapState(useGamesCategory, {
            getCategoryGames: "getCollection",
        }),
        ...mapState(useGamesCommon, [ "getGameCategoryNameBySlug" ]),
        ...mapState(useCMS, {
            pageSeoMain: "getSeoMainPageFooter",
            seoCurrentDescription: "seoCurrentDescription",
        }),

        isMain() {
            return this.$route.name === routeNames.main;
        },

        isMainOrLobby() {
            return routeNames.main === this.$route.name;
        },

        currentPageSeo() {
            return this.isMain ? this.pageSeoMain : this.seoCurrentDescription;
        },

        showReadMoreBlock() {
            return !this.isLogged && Boolean(this.currentPageSeo);
        },
    },

    created() {
        const url = useRoute().path;
        const entity = this.getGameCategoryOrProviderByUrl(url);

        if (entity) {
            this.setSeoMeta({ meta: entity.meta, url });
            this.setCurrentPageSeoDescription(entity.description);
        }
    },

    methods: {
        ...mapActions(useGamesProviders, [
            "getGameCategoryOrProviderByUrl",
        ]),

        getCategoryName(slug) {
            return this.getGameCategoryNameBySlug(slug);
        },
    },

};
</script>

<style lang="scss" src="./style.scss"></style>
<style lang="scss" src="../Bonus/style.scss"></style>
